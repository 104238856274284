<template>
    <div class="fhf container">
        <div class="row">
            <div class="col col-6">
                <mercur-card class="my-4" v-if="isAllowedTo('addSupplierQuoteParticipant')">
                    <h4 class="font-weight-normal mt-0 mb-0">Select supplier</h4>
                    <div class="row mb-2">
                        <mercur-select
                            v-if="availableSuppliers"
                            v-model="selectedSupplier"
                            class="col-7"
                            ref="supplierSelector"
                        >
                            <option :value="supplier" v-for="(supplier, key) in availableSuppliers" :key="key">
                                {{supplier.supplierName}}
                            </option>
                        </mercur-select>
                        <div class="col-5">
                            <mercur-button class="d-block btn btn-raised btn-yellow" :disabled="!selectedSupplier" @click="addSupplier(selectedSupplier)">
                                Add selected supplier
                            </mercur-button>
                        </div>
                    </div>
                    <router-link :to="createNewSupplierRoute"><small>Create new supplier</small></router-link>
                </mercur-card>
            </div>

            <div class="col col-6 fhf">
                <mercur-card class="my-4">
                    <div v-if="!quoteParticipants.length" class="align-center"><h2 class="font-weight-normal">No suppliers selected</h2></div>
                    <template v-else>
                        <table class="col-12 p-0">
                            <tr v-for="(participant, index) in quoteParticipants" :key="index">
                                <td><mercur-checkbox v-model="sendQueue" :value="participant.supplierId" /></td>
                                <td>
                                    <span>
                                        {{participant.supplierName}} {{ participant.dateRevoked ? '(declined by supplier)' : ''}}
                                    </span>
                                </td>
                                <td class="text-right">
                                    <mercur-button v-if="participant.proposalFile" class="btn btn-icon" title="Download Quote" @click="downloadSupplierQuote(participant)">
                                        <i class="fas fa-download" />
                                    </mercur-button>
                                    <mercur-button class="btn btn-icon" title="Copy Proposal URL" @click="copySupplierProposalUrl(participant)" v-if="isAllowedTo('addSupplierQuoteParticipant')">
                                        <i class="fas fa-copy" />
                                    </mercur-button>
                                    <mercur-button class="btn btn-icon" title="Remove" @click="removeSupplier(participant)" v-if="isAllowedTo('removeSupplierQuoteParticipant')">
                                        <i class="fas fa-trash" />
                                    </mercur-button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="p-0"><hr /></td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <mercur-checkbox v-model="sendQueueAll" id="select-all"><small>select all/none</small></mercur-checkbox>
                                </td>
                            </tr>
                        </table>
                    </template>
                    <div class="mt-4 row">
                        <div class="col-6">
                            <mercur-button
                                @click.prevent="submitSendEmail"
                                :disabled="!canSendToSuppliers"
                                class="btn btn-primary w-100">
                                <i class="fas fa-envelope" /> Send to selected supplier
                            </mercur-button>
                            <p v-if="!isQuoteDateRangeSet" class="error">Please set the quote date range before sending to supplier</p>
                        </div>
                    </div>
                </mercur-card>
            </div>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'SuppliersSelect',

    props: ['quoteParticipants', 'supplierQuoteId', 'accountId', 'isQuoteDateRangeSet'],

    data () {
        return {
            selectedSupplier: null,
            allSuppliers: null,
            supplierQuoteTokenList: [],
            isLoading: false,
            sendQueue: [],
            createNewSupplierRoute: {
                name: 'SuppliersView',
                query: {
                    'openModal': 'addSupplier',
                },
            },
        }
    },

    created () {
        const params = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
        params.request.sortModel.push({
            colId: 'supplierName',
            sort: 'asc',
        })
        const url = CONFIG.API.ROUTES.SUPPLIERS.OVERVIEW
        this.addJob(url)
        this.$api.post(url, params).then(({ data }) => {
            this.$watch('quoteParticipants', () => {
                if (this.quoteParticipants.length === 0) {
                    this.$set(this, 'allSuppliers', data.data.items)
                    return
                }

                this.$set(this, 'allSuppliers', data.data.items.map(supplier => {
                    const quoteParticipant = this.quoteParticipants.find(participant => participant.supplierId === supplier.supplierId)
                    if (quoteParticipant) {
                        supplier.quoteToken = quoteParticipant.quoteToken
                    }

                    return supplier
                }))
            }, {
                immediate: true,
            })
        }).finally(() => {
            this.finishJob(url)
        })
    },

    computed: {
        canSendToSuppliers () {
            if (this.isLoading) {
                return false
            }
            if (!this.isQuoteDateRangeSet) {
                return false
            }
            if (this.sendQueue.length === 0) {
                return false
            }

            return true
        },
        sendQueueAll: {
            get () {
                return this.quoteParticipants.every(participant => this.sendQueue.includes(participant.supplierId))
            },
            set (value) {
                if (!value) {
                    this.$set(this, 'sendQueue', [])
                    return
                }
                this.$set(this, 'sendQueue', this.quoteParticipants.map(supplier => supplier.supplierId))
            },
        },
        availableSuppliers () {
            if (!Array.isArray(this.allSuppliers)) {
                return null
            }
            const participantIds = this.quoteParticipants.map(supplier => supplier.supplierId)
            return this.allSuppliers.filter(possibleSupplier => !participantIds.includes(possibleSupplier.supplierId))
        },
    },

    methods: {
        submitSendEmail () {
            const payload = {
                supplierQuoteId: this.supplierQuoteId,
                supplierIds: this.sendQueue,
            }
            const jobName = this.sendQueue.join('')
            const url = CONFIG.API.ROUTES.QUOTES.SUPPLIERS.SEND_EMAIL.replace('{supplierQuoteId}', this.supplierQuoteId)
            this.addJob(jobName)
            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Sending emails to supplier done`,
                })
                this.$emit('wasSendToSuppliers', true)
            }).catch((data) => {
                this.$root.$emit('notification:global', {
                    message: `Sending emails to supplier failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(jobName)
                this.$set(this, 'sendQueue', [])
            })
        },
        removeSupplier (supplier) {
            const url = CONFIG.API.ROUTES.QUOTES.SUPPLIERS.REMOVE
                .replace('{supplierQuoteId}', this.$route.params.supplierQuoteId)

            const payload = {
                supplierId: supplier.supplierId,
                supplierQuoteId: this.$route.params.supplierQuoteId,
            }

            this.addJob(supplier.supplierId)
            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Suppliers successfully updated!`,
                })
                this.$emit('refreshQuote')
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong. Please try again.',
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(supplier.supplierId)
            })
        },

        addSupplier (newParticipant) {
            this.$set(this, 'selectedSupplier', null)
            setTimeout(() => {
                this.$refs.supplierSelector.$refs.select.clearSelection()
            }, 1000)
            this.generateSupplierQuoteToken(newParticipant).then(quoteToken => {
                const url = CONFIG.API.ROUTES.QUOTES.SUPPLIERS.ADD
                    .replace('{supplierQuoteId}', this.$route.params.supplierQuoteId)

                const payload = {
                    supplierId: newParticipant.supplierId,
                    supplierQuoteId: this.$route.params.supplierQuoteId,
                    quoteToken,
                }

                this.addJob(newParticipant.supplierId)
                this.$api.post(url, payload).then(() => {
                    this.$root.$emit('notification:global', {
                        message: `Suppliers successfully updated!`,
                    })
                    this.$emit('refreshQuote')
                }).catch(e => {
                    this.$root.$emit('notification:global', {
                        message: 'Something went wrong. Please try again.',
                        type: 'error',
                    })
                }).finally(() => {
                    this.finishJob(newParticipant.supplierId)
                })
            })
        },

        sendEmail () {
            if (!this.isAllowedTo('sendSupplierQuoteToParticipants')) {
                return
            }

            const url = CONFIG.API.ROUTES.QUOTES.SUPPLIERS.SEND_EMAIL.replace('{supplierQuoteId}', this.$route.params.supplierQuoteId)

            const sendEmailParams = {
                supplierQuoteId: this.$route.params.supplierQuoteId,
                supplierIds: this.quoteParticipants.map(supplier => supplier.supplierId),
            }

            this.addJob(url)
            this.$api.post(url, sendEmailParams).then((data) => {
                this.$root.$emit('notification:global', {
                    message: `Email successfully sent!`,
                })
                this.$emit('wasSendToSuppliers', true)
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },

        generateSupplierQuoteToken ({ supplierId }) {
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.QUOTES.SUPPLIERS.GENERATE_TOKEN

                const payload = {
                    supplierQuoteId: this.supplierQuoteId,
                    supplierId,
                    cartId: this.$route.params.cartId,
                }

                this.addJob(url)
                this.$api.post(url, payload).then(({ data }) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err)
                    this.$root.$emit('notification:global', {
                        message: err,
                        type: 'error',
                        errors: err,
                    })
                }).finally(() => {
                    this.finishJob(url)
                })
            })
        },

        downloadSupplierQuote (participant) {
            const url = CONFIG.API.ROUTES.QUOTES.PROPOSAL.DOWNLOAD.replace('{supplierQuoteId}', participant.supplierQuoteId)

            const payload = {
                objectName: participant.proposalFile,
                supplierQuoteId: participant.supplierQuoteId,
                supplierId: participant.supplierId,
            }

            this.$root.$emit('notification:global', {
                message: 'Your download is starting',
            })
            this.addJob(url)
            this.$store.dispatch('tasks/addTask', {
                title: `Download quote proposal csv`,
                interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                retryOnFail: true,
                onComplete: data => {
                    for (const file of data) {
                        this.$bus.$emit('DOWNLOAD_URL_RESOLVED', file.url)
                    }
                },
                onPoll: () => {
                    this.$root.$emit('notification:global', {
                        message: `Download started.`,
                    })
                    return this.$api.post(url, payload).catch(data => {
                        this.$root.$emit('notification:global', {
                            message: `Downloading failed. Please try again.`,
                            type: 'error',
                            errors: data,
                        })
                    }).finally(() => {
                        this.finishJob(url)
                    })
                },
                handleResult: (result) => {
                    if (result.data.length === 0) {
                        return Promise.resolve(null)
                    }
                    return Promise.resolve(result.data)
                },
            })
        },
        copySupplierProposalUrl (participant) {
            const el = document.createElement('textarea')
            const routePath = this.$router.resolve({
                name: 'QuoteProposalView',
                params: {
                    token: participant.quoteToken,
                },
            })
            const link = document.createElement('a')
            link.href = routePath.href
            el.value = link.href
            el.setAttribute('readonly', '')
            el.style.position = 'absolute'
            el.style.left = '-9999px'
            document.body.appendChild(el)
            const selected =
                document.getSelection().rangeCount > 0
                    ? document.getSelection().getRangeAt(0)
                    : false
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)
            if (selected) {
                document.getSelection().removeAllRanges()
                document.getSelection().addRange(selected)
            }

            this.$root.$emit('notification:global', {
                message: `Supplier token copied to clipboard`,
            })
        },
    },
}
</script>
