<template>
    <mercur-card class="fill full-height-layout mx-3 mt-1">
        <div class="d-flex">
            <div class="flex-grow-1">
                <h2 class="my-2">Product: {{quoteItem.productName}}</h2>
                <h4 class="font-weight-normal my-0">Cart Item ID: {{quoteItem.supplierQuoteItemId}}</h4>
            </div>
            <slot></slot>
        </div>
        <div>
            <form @submit.prevent="saveQuoteItem" class="my-3">
                <div class="mb-3">
                    <div>
                        <div v-for="(productAttribute, key) in getNormalizedAttributes(quoteItem.attributes)" :key="key">
                            <b>{{productAttribute.attributeName}}: </b>{{productAttribute.attributeOption}}
                        </div>
                    </div>
                    <div>
                        <b>Quantity:</b> {{quoteItem.quantity}}
                    </div>
                    <template v-if="quoteItem.deliveryInfo">
                        <div v-if="quoteItem.deliveryInfo.desiredDeliveryDate">
                            <b>Desired delivery date:</b> {{quoteItem.deliveryInfo.desiredDeliveryDate}}
                        </div>
                        <div v-if="quoteItem.deliveryInfo.address && quoteItem.deliveryInfo.address.country">
                            <b>Desired delivery country:</b> {{quoteItem.deliveryInfo.address.country}}
                        </div>
                    </template>
                    <div v-for="(shopAttribute, key) in quoteItem.shopAttributes" :key="key">
                        <b>{{key}}:</b> {{shopAttribute}}
                    </div>
                </div>
                <div v-if="quoteParticipants && quoteParticipants.length > 0">
                    <mercur-select
                        class="participant-dropdown"
                        v-model="quoteItem.producer.supplierId"
                        ref="participantSelection">
                        <template slot="label">Select Participant</template>
                        <option :value="participant.supplierId" v-for="(participant, key) in quoteParticipants" :key="key">
                            {{participant.supplierName}}
                        </option>
                    </mercur-select>

                    <div v-if="participantLocations && participantLocations.length">
                        <mercur-select
                            class="location-dropdown"
                            v-model="quoteItem.producer.facilityId"
                            ref="locationSelection">
                            <template slot="label">Select Location</template>
                            <option :value="location.locationId" v-for="(location, key) in participantLocations" :key="key">
                                {{location.locationName}}
                            </option>
                        </mercur-select>
                    </div>
                </div>

                <div>
                    <mercur-select
                        v-if="quoteCarriers && quoteCarriers.length"
                        class="carrier-dropdown"
                        v-model="quoteItem.carrier.serviceLevelId"
                        ref="carrierSelection">
                        <template slot="label">Select Carrier/Service Level</template>
                        <option :value="carrier.serviceLevelId" v-for="(carrier, key) in quoteCarriers" :key="key">
                            {{carrier.serviceLevelName}}
                        </option>
                    </mercur-select>
                </div>

                <div>
                    <mercur-input v-model.number="quoteItem.producer.productionDays" type="number" step="0.5">
                        Production days:
                    </mercur-input>
                </div>

                <div>
                    <mercur-input v-model="quoteItem.deliveryInfo.targetDispatchDate" type="date">
                        Target dispatch date:
                    </mercur-input>
                </div>

                <div>
                    <mercur-input v-model="quoteItem.deliveryInfo.targetDeliveryDate" type="date">
                        Target delivery date:
                    </mercur-input>
                </div>

                <div>
                    <mercur-input v-model.number="quoteItem.carrier.transitDays" type="number" step="0.5">
                        Transit days:
                    </mercur-input>
                </div>

                <div>
                    <mercur-input v-model.number="quoteItem.producer.packs" type="number">Packs:</mercur-input>
                </div>
                <div>
                    <mercur-input v-model.number="quoteItem.productInfo.weight" type="number">Package weight (g):</mercur-input>
                </div>
                <div>
                    <mercur-input v-model.number="quoteItem.itemReference">Supplier reference:</mercur-input>
                </div>
                <div>
                    <mercur-input v-model.number="quoteItem.prices.productCost">Product costs:</mercur-input>
                </div>
                <div>
                    <mercur-input v-model.number="quoteItem.prices.shippingCost">Shipping costs:</mercur-input>
                </div>
                <div class="text-right">
                    <mercur-button
                        class="btn btn-yellow btn-raised"
                        :disabled="isLoading"
                        type="submit">Save
                    </mercur-button>
                </div>
            </form>
        </div>
    </mercur-card>
</template>
<script>
import CONFIG from '@root/config'
import { getDeepProperty } from '@/components/utils/Utils'

export default {
    name: 'QuoteItem',
    props: {
        quoteItem: {
            type: Object,
            required: true,
        },
        quoteParticipants: {
            type: Array,
            required: true,
        },
        quoteCarriers: {
            type: Array,
            required: true,
        },
    },

    data () {
        return {
            participantLocations: null,
            isLoading: false,
        }
    },

    computed: {
        transformedQuoteItem () {
            return {
                productInfo: {
                    weight: getDeepProperty('quoteItem.productInfo.weight', this),
                },
                packs: getDeepProperty('quoteItem.producer.packs', this),
                supplierId: getDeepProperty('quoteItem.producer.supplierId', this),
                facilityId: getDeepProperty('quoteItem.producer.facilityId', this),
                productionDays: getDeepProperty('quoteItem.producer.productionDays', this),
                serviceLevelId: getDeepProperty('quoteItem.carrier.serviceLevelId', this),
                transitDays: getDeepProperty('quoteItem.carrier.transitDays', this),
                targetDeliveryDate: getDeepProperty('quoteItem.deliveryInfo.targetDeliveryDate', this),
                targetDispatchDate: getDeepProperty('quoteItem.deliveryInfo.targetDispatchDate', this),
                deliveryDate: getDeepProperty('quoteItem.deliveryInfo.targetDeliveryDate', this),
                dispatchDate: getDeepProperty('quoteItem.deliveryInfo.targetDispatchDate', this),
                itemReference: getDeepProperty('quoteItem.itemReference', this) ? getDeepProperty('quoteItem.itemReference', this).toString() : '',
                prices: {
                    productCost: getDeepProperty('quoteItem.prices.productCost', this),
                    shippingCost: getDeepProperty('quoteItem.prices.shippingCost', this),
                },
            }
        },
    },
    watch: {
        'quoteItem.producer.supplierId': {
            handler (supplierId) {
                this.fetchLocations(supplierId)
            },
            immediate: true,
        },
    },

    methods: {
        fetchLocations (supplierId) {
            if (!supplierId) {
                return
            }
            const urlLocations = CONFIG.API.ROUTES.SUPPLIERS.LOCATIONS.replace('{supplierId}', supplierId)
            this.addJob(urlLocations)
            this.$api.post(urlLocations, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                const facilities = data.data.items.filter(location => {
                    return location.locationType === 'FACILITY'
                })

                this.$set(this, 'participantLocations', facilities)
            }).finally(() => {
                this.finishJob(urlLocations)
            })
        },
        saveQuoteItem () {
            const url = CONFIG.API.ROUTES.QUOTES.UPDATE_QUOTE_ITEM.replace('{supplierQuoteId}', this.quoteItem.supplierQuoteId).replace('{supplierQuoteItemId}', this.quoteItem.supplierQuoteItemId)
            this.addJob(url)
            this.isLoading = true
            this.$emit('updateQuoteItemRaw', this.transformedQuoteItem)
            this.$api.post(url, this.transformedQuoteItem).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Quote item edited successfully!`,
                    type: 'success',
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Editing quote item failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.isLoading = false
                this.finishJob(url)
            })
        },

        getNormalizedAttributes (attributes) {
            return Object.entries(attributes).map(([key, value]) => {
                if (typeof value === 'object' && value !== null) {
                    return value
                }

                return {
                    attributeName: key,
                    attributeOption: value,
                }
            })
        },
    },
    created () {
        this.$emit('updateQuoteItemRaw', this.transformedQuoteItem)
    },
}
</script>
