<template>
    <div class="fhf">
        <div class="fhf u-rel" v-if="isAllowedTo('getSupplierQuoteById')">
            <div class="actions" v-if="quoteData">
                <mercur-button @click="sendQuoteToMerchant" v-if="isAllowedTo('sendQuoteToMerchant')" :disabled="$v.quoteItemsRaw.$invalid || isLoading" class="btn btn-yellow btn-raised text-uppercase">Send to Merchant</mercur-button>
                <mercur-button @click="$refs.suppliersSelect.sendEmail()" v-if="quoteData.quoteStatus === 'AWAITING_QUOTATION' && isAllowedTo('sendSupplierQuoteToParticipants')" :disabled="isLoading || !quoteParticipants || quoteParticipants.length === 0" class="btn btn-yellow btn-raised text-uppercase mr-4">Send to all participants</mercur-button>
            </div>
            <mercur-tabs class="quote-tabs fhf">
                <mercur-tab id="quote-details" title="Quote Details" v-if="isAllowedTo('getSupplierQuoteById')">
                    <mercur-card class="quote-details-card fhf">
                        <div v-if="!quoteData" class="loading-indicator">
                            <mercur-spinner />
                        </div>
                        <template v-if="quoteData">
                            <div class="container">
                                <div class="row">
                                    <div class="col-6">
                                        <table class="quote-summary">
                                            <tr>
                                                <td>Quote number:</td>
                                                <td><b>{{quoteData.quoteNumber}}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Quote title:</td>
                                                <td>
                                                    <span v-if="quoteData.quoteTitle">{{quoteData.quoteTitle}}</span>
                                                    <em v-else>none</em>
                                                    <mercur-button class="btn btn-icon btn-icon-sm btn-outline-grey" data-test="QuoteEditTitle" @click="quoteTitleEditDialogActive = true"><i class="fas fa-edit"/></mercur-button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Assigned to:</td>
                                                <td>
                                                    <span v-if="quoteAssignee">{{quoteAssignee.firstName}} {{quoteAssignee.lastName}}</span>
                                                    <em v-else>none</em>
                                                    <mercur-button class="btn btn-icon btn-icon-sm btn-outline-grey" @click="openAssigneeEditDialog"><i class="fas fa-edit"/></mercur-button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Date created:</td>
                                                <td>{{quoteData.dateCreated}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-6">
                                        <h2 class="font-weight-normal mt-2">
                                            Proposals date range
                                        </h2>
                                        <p>Suppliers can upload proposals between</p>
                                        <div class="d-flex">
                                            <div class="flex-1 daterangepicker-wrapper">
                                                <mercur-date-picker
                                                    @update="dateRangeUpdate"
                                                    :timePicker="true"
                                                    :timePicker24Hour="true"
                                                    :dateRange="quoteDateRange"
                                                    :ranges="false"
                                                    opens="left"
                                                    ref="dateRangePicker">
                                                    <template slot="preview-range" slot-scope="preview">
                                                        <strong>{{preview.startText}}</strong>
                                                        &nbsp;to&nbsp;
                                                        <strong>{{preview.endText}}</strong>
                                                    </template>
                                                    <template slot="input">
                                                        <mercur-input :value="parsedQuoteDate" :disabled="quoteData === null" readonly>
                                                            <template #note v-if="!isQuoteDateRangeSet">
                                                                <span  class="error">Please set quote dates</span>
                                                            </template>
                                                        </mercur-input>
                                                    </template>
                                                </mercur-date-picker>
                                            </div>
                                            <mercur-button @click="updateQuoteDates" class="btn btn-red ml-1" :disabled="!$v.quoteData.$dirty || $v.quoteData.$invalid || isQuoteDataSubmitting" v-if="isAllowedTo('updateSupplierQuote')">Save</mercur-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="col-12"/>
                            <div class="p-2">
                                <h2 class="font-weight-normal mt-2">Attachments</h2>
                            </div>
                            <div class="attachments fill">
                                <div class="attachments-table">
                                    <table class="col-12" v-if="isAllowedTo('listQuoteAttachmentsByQuoteIdAndActor')">
                                        <tr class="text-left border-bottom">
                                            <th>File</th>
                                            <th>Uploaded by</th>
                                            <th>Visible for suppliers</th>
                                        </tr>
                                        <tr><td colspan="4" class="border-top"></td></tr>
                                        <tr v-for="(attachment, key) in quoteAttachments" :key="key">
                                            <td>
                                                <a href="#" @click="downloadAttachment(attachment)" :disabled="attachment.downloadPending" v-if="isAllowedTo('downloadQuoteAttachment')">{{attachment.originalFileName}}</a>
                                            </td>
                                            <td>
                                                {{attachment.uploaderType | beautify}}
                                            </td>
                                            <td>
                                                <mercur-checkbox class="attachment-permission"
                                                             @change="setQuoteAttachmentPermission(attachment, $event)"
                                                             v-model="attachment.permissionSupplier"
                                                             v-if="isAllowedTo('setPermissionForQuoteAttachment')"/>
                                            </td>
                                            <td class="text-right">
                                                <mercur-button class="btn btn-icon btn-outline-grey" @click="downloadAttachment(attachment)" v-if="isAllowedTo('downloadQuoteAttachment')" :disabled="attachment.downloadPending"><i class="fas fa-download" /></mercur-button>
                                            </td>
                                        </tr>
                                    </table>
                                    <p class="permission-message" v-else>Not allowed to see this view</p>
                                    <div v-if="!quoteAttachments && isAllowedTo('listQuoteAttachmentsByQuoteIdAndActor')" class="loading-indicator">
                                        <mercur-spinner />
                                    </div>
                                    <div class="d-flex items-center p-4 justify-content-center"
                                        v-if="quoteAttachments && quoteAttachments.length === 0">
                                        No attachments found
                                    </div>
                                </div>
                            </div>
                        </template>
                    </mercur-card>
                </mercur-tab>

                <mercur-tab id="quote-items" title="Quote Items" class="quote-items" v-if="isAllowedTo('getSupplierQuoteById')">
                    <div class="fhf" v-if="quoteItems">
                        <div v-if="quoteItems.length" class="quote-items">
                            <div
                                v-for="(quoteItem, key) in quoteItems"
                                :key="key"
                                class="quote-item">
                                <quote-item
                                    :quote-item.sync="quoteItem"
                                    @updateQuoteItemRaw="handleRawQuoteItem($event, key)"
                                    :quote-participants="quoteParticipants"
                                    :quote-carriers="quoteCarriers">
                                    <mercur-button class="btn btn-icon" @click="duplicateQuoteItem(quoteItem)" v-if="isAllowedTo('addItemToCart')">
                                        <i class="fas fa-copy" />
                                    </mercur-button>
                                    <mercur-button class="btn btn-icon" @click="removeQuoteItem(quoteItem)" v-if="isAllowedTo('removeItemFromCart')">
                                        <i class="fas fa-trash" />
                                    </mercur-button>
                                </quote-item>
                            </div>
                        </div>
                        <p v-else>No quote items are found</p>
                    </div>
                    <div v-else>
                        <p>Loading quote items...</p>
                        <mercur-spinner />
                    </div>
                </mercur-tab>

                <mercur-tab id="quote-suppliers" title="Suppliers" class="quote-suppliers">
                    <suppliers-select
                        ref="suppliersSelect"
                        :quote-participants="quoteParticipants"
                        :supplier-quote-id="supplierQuoteId"
                        :account-id="accountId"
                        :is-quote-date-range-set="isQuoteDateRangeSet"
                        @wasSendToSuppliers="getQuoteDetails()"
                        @refreshQuote="getQuoteDetails()"
                    />
                </mercur-tab>
            </mercur-tabs>
        </div>

        <p class="permission-message" v-else>Not allowed to see this view</p>
        <mercur-dialog :is-open.sync="addNewQuoteItemDialogActive">
            <template slot="header">
                <h3 class="font-weight-normal mt-0">Add quote item</h3>
            </template>
            <div class="mx-4">
                <mercur-input v-model.trim="$v.newQuoteItem.productName.$model"
                    :class="{'form-invalid': $v.newQuoteItem.productName.$error}"
                    :disabled="addNewQuoteItemDialogLoading"
                    required
                    ref="addCarrierNameInputfield"
                    autofocus>
                    Product name
                    <template slot="note">
                        <span class="form-error" v-if="!$v.newQuoteItem.productName.$error">Product name is required</span>
                    </template>
                </mercur-input>
                <div class="attribute-wrapper">
                    <div class="text-mini text-grey">Attributes</div>
                    <div v-for="($attribute, key) in $v.newQuoteItem.attributes.$each.$iter" :key="key" class="flex attribute">
                        <div class="attribute-field attribute-field--name">
                            <mercur-input v-model="$attribute.attributeName.$model">
                                <span v-if="key === '0'">Attribute Name</span>
                            </mercur-input>
                        </div>
                        <div class="attribute-field attribute-field--option">
                            <mercur-input v-model="$attribute.attributeOption.$model">
                                <span v-if="key === '0'">Attribute Option</span>
                            </mercur-input>
                        </div>
                        <a class="attribute-remove" v-if="newQuoteItem.attributes.length > 1" @click="newQuoteItem.attributes.splice(key, 1)">-</a>
                    </div>
                    <button class="btn btn-icon btn-icon-sm" @click="addNewAttribute" ><i class="fas fa-plus" /></button>
                </div>
                <div>
                    <mercur-input type="number" v-model="$v.newQuoteItem.quantity.$model">
                        Quantity
                    </mercur-input>
                </div>
            </div>
            <template slot="footer">
                <mercur-button class="btn" @click="addNewQuoteItemDialogActive = false">Close</mercur-button>
                <mercur-button class="btn btn-yellow" :disabled="addNewQuoteItemDialogLoading || $v.newQuoteItem.$invalid" @click="saveNewQuoteItem" v-if="isAllowedTo('addItemToCart')">Save</mercur-button>
            </template>
            <mercur-progress-bar :indeterminate="true" v-if="addNewQuoteItemDialogLoading"></mercur-progress-bar>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="assigneeEditDialogActive">
            <template slot="header">
                <h3 class="font-weight-normal mt-0">Edit assignee</h3>
            </template>
            <div class="mx-4">
                <pretty-select
                    v-if="availableAssignees"
                    label="fullName"
                    :options="availableAssignees"
                    :reduce="assignee => assignee.accountId"
                    v-model="assigneeEditId"
                >
                </pretty-select>
            </div>

            <template slot="footer">
                <mercur-button class="btn btn-yellow text-uppercase" @click="assigneeEditDialogActive = false">Close</mercur-button>
                <mercur-button class="btn btn-yellow text-uppercase" :disabled="assigneeEditDialogLoading" @click="saveQuoteAssignee">Save</mercur-button>
            </template>
            <mercur-progress-bar :indeterminate="true" v-if="assigneeEditDialogLoading"></mercur-progress-bar>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="quoteTitleEditDialogActive">
            <template slot="header">
                <h3 class="font-weight-normal">Edit title</h3>
            </template>
            <div class="mx-2">
                <mercur-input v-model="quoteData.quoteTitle">
                    Quote title:
                </mercur-input>
            </div>

            <template slot="footer">
                <mercur-button class="btn text-uppercase" @click="quoteTitleEditDialogActive = false">Close</mercur-button>
                <mercur-button class="btn btn-yellow text-uppercase" data-test="quoteTitleSaveButton" :disabled="quoteTitleEditDialogLoading" @click="saveQuoteTitle">Save</mercur-button>
            </template>
            <mercur-progress-bar indeterminate v-if="quoteTitleEditDialogLoading"></mercur-progress-bar>
        </mercur-dialog>
    </div>
</template>

<script>
import SuppliersSelect from '@/components/quotes/SuppliersSelect'
import CONFIG from '@root/config'
import QuoteItem from '../../components/quotes/QuoteItem'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import PrettySelect from '../../components/utils/PrettySelect'
export default {
    name: 'QuoteDetails',
    mixins: [ validationMixin ],
    components: { PrettySelect, QuoteItem, SuppliersSelect },
    props: ['organisationAccounts'],
    data () {
        return {
            quoteItems: null,
            quoteItemsRaw: [],
            quoteParticipants: [],
            participantsLocations: null,
            quoteCarriers: [],
            quoteAttachments: null,
            accountId: null,
            isLoading: false,
            isQuoteDataSubmitting: false,
            quoteData: {
                quoteTitle: '',
            },
            startDate: null,
            endDate: null,
            addNewQuoteItemDialogActive: false,
            addNewQuoteItemDialogLoading: false,
            newQuoteItem: null,
            assigneeEditDialogActive: false,
            assigneeEditDialogLoading: false,
            assigneeEditId: null,
            quoteTitleEditDialogActive: false,
            quoteTitleEditDialogLoading: false,
        }
    },

    computed: {
        isQuoteDateRangeSet () {
            if (!this.quoteData) {
                return false
            }
            if (!this.quoteData.quoteStartDate) {
                return false
            }
            return true
        },
        supplierQuoteId () {
            return this.$route.params.supplierQuoteId
        },
        quoteDateRange () {
            return {
                startDate: (this.startDate || moment().subtract(1, 'days').hour(0).minute(0)).toDate(),
                endDate: (this.endDate || moment()).toDate(),
            }
        },
        parsedQuoteDate () {
            if (this.startDate === null || this.endDate === null) {
                return ''
            }
            return this.formatDate(this.startDate) + ' - ' + this.formatDate(this.endDate)
        },
        quoteAssignee () {
            if (!this.organisationAccounts || !this.quoteData) {
                return {}
            }
            return this.organisationAccounts.find(({ accountId }) => {
                return accountId === this.quoteData.quoteAssigneeId
            })
        },
        availableAssignees () {
            if (this.organisationAccounts === null) {
                return []
            }

            return this.organisationAccounts
        },
    },
    validations: {
        quoteItemsRaw: {
            required,
            $each: {
                packs: { required },
                supplierId: { required },
                facilityId: { required },
                productionDays: { required },
                serviceLevelId: { required },
                transitDays: { required },
                targetDeliveryDate: { required },
                targetDispatchDate: { required },
                prices: {
                    productCost: { required },
                    shippingCost: { },
                },
            },
        },
        quoteData: {
            quoteStartDate: {
                required,
            },
            quoteEndDate: {
                required,
            },
        },
        newQuoteItem: {
            productName: {
                required,
            },
            attributes: {
                required,
                $each: {
                    attributeName: { required },
                    attributeOption: { required },
                },
            },
            quantity: {
                required,
            },
        },
    },

    methods: {
        openAssigneeEditDialog () {
            this.assigneeEditId = this.quoteData.quoteAssigneeId
            this.assigneeEditDialogActive = true
        },
        saveQuoteAssignee () {
            if (!this.isAllowedTo('updateSupplierQuote')) {
                return
            }

            const url = CONFIG.API.ROUTES.QUOTES.UPDATE.replace('{supplierQuoteId}', this.supplierQuoteId)
            const jobName = url
            this.addJob(jobName)
            this.assigneeEditDialogLoading = true

            this.$api.post(url, {
                quoteAssigneeId: this.assigneeEditId,
            }).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Quote edited successfully`,
                })
                this.$v.quoteData.$reset()
                this.assigneeEditDialogActive = false
                this.quoteData.quoteAssigneeId = this.assigneeEditId
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Quote editing failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(jobName)
                this.assigneeEditDialogLoading = false
            })
        },
        saveQuoteTitle () {
            if (!this.isAllowedTo('updateSupplierQuote')) {
                return
            }

            const url = CONFIG.API.ROUTES.QUOTES.UPDATE.replace('{supplierQuoteId}', this.supplierQuoteId)
            const jobName = url
            this.addJob(jobName)
            this.quoteTitleEditDialogLoading = true

            this.$api.post(url, {
                quoteTitle: this.quoteData.quoteTitle,
            }).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Quote edited successfully`,
                })
                this.$v.quoteData.$reset()
                this.assigneeEditDialogActive = false
                this.quoteData.quoteAssigneeId = this.assigneeEditId
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Quote editing failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(jobName)
                this.quoteTitleEditDialogLoading = false
            })
        },
        downloadAttachment (attachment) {
            if (!this.isAllowedTo('downloadQuoteAttachment')) {
                return
            }

            const url = CONFIG.API.ROUTES.QUOTES.ATTACHMENTS.DOWNLOAD.replace('{quoteId}', this.quoteData.quoteId)
            const jobName = attachment.objectName
            this.addJob(jobName)
            this.$set(attachment, 'downloadPending', true)
            this.$store.dispatch('tasks/addTask', {
                title: `Download quote detail csv`,
                interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                retryOnFail: true,
                onComplete: data => {
                    this.$bus.$emit('DOWNLOAD_URL_RESOLVED', data.data.url)
                },
                onPoll: () => {
                    this.$root.$emit('notification:global', {
                        message: `Download started.`,
                    })
                    return this.$api.post(url, attachment).catch(data => {
                        this.$root.$emit('notification:global', {
                            message: `Downloading failed. Please try again.`,
                            type: 'error',
                            errors: data,
                        })
                    }).finally(() => {
                        attachment.downloadPending = false
                        this.finishJob(jobName)
                    })
                },
                handleResult: (result) => {
                    if (result.data.length === 0) {
                        return Promise.resolve(null)
                    }
                    return Promise.resolve(result.data)
                },
            })
        },
        setQuoteAttachmentPermission (attachment, action, permissionType = 'SUPPLIER') {
            if (!this.isAllowedTo('setPermissionForQuoteAttachment')) {
                return
            }

            const url = CONFIG.API.ROUTES.QUOTES.ATTACHMENTS.SET_PERMISSION.replace('{quoteId}', this.quoteData.quoteId).replace('{quoteAttachmentId}', attachment.quoteAttachmentId)
            const jobName = attachment.objectName
            this.addJob(jobName)
            const payload = {
                accountId: this.quoteData.accountId,
                permissionType,
                action: action ? 'GRANT' : 'REVOKE',
            }
            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Permission successfully saved`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Setting permission failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
                attachment.permissionSupplier = !attachment.permissionSupplier
            }).finally(() => {
                this.finishJob(jobName)
            })
        },
        resetNewQuoteItemParams () {
            this.newQuoteItem = {
                productName: null,
                attributes: [
                    {
                        attributeName: null,
                        attributeOption: null,
                    },
                ],
                quantity: null,
            }
        },
        addNewAttribute () {
            this.newQuoteItem.attributes.push({
                attributeName: null,
                attributeOption: null,
            })
        },
        duplicateQuoteItem (quoteItem) {
            this.resetNewQuoteItemParams()
            const quoteItemCopy = JSON.parse(JSON.stringify(quoteItem))
            this.newQuoteItem.productName = quoteItemCopy.productName
            this.newQuoteItem.quantity = quoteItemCopy.quantity
            this.newQuoteItem.attributes = quoteItemCopy.attributes
            this.newQuoteItem.deliveryInfo = quoteItemCopy.deliveryInfo

            this.addNewQuoteItemDialogActive = true
        },
        removeQuoteItem (quoteItem) {
            const url = CONFIG.API.ROUTES.QUOTES.REMOVE_QUOTE_ITEM
                .replace('{supplierQuoteId}', quoteItem.supplierQuoteId)
                .replace('{supplierQuoteItemId}', quoteItem.quoteItemId)

            const payload = {
                aggregateId: this.quoteData.cartId,
                cartId: this.quoteData.cartId,
                applicationName: this.quoteData.applicationName,
                applicationId: this.quoteData.applicationId,
                sourceId: this.quoteData.cartId,
                cartItem: {
                    cartItemId: quoteItem.quoteItemId,
                    sourceId: quoteItem.quoteItemId,
                },
                accountId: this.accountId,
            }

            const jobName = url
            this.addJob(jobName)
            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Quote item was removed`,
                })
                this.getQuoteDetails()
                this.resetNewQuoteItemParams()
            }).catch(() => {
                this.$root.$emit('notification:global', {
                    message: `Something went wrong`,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(jobName)
            })
        },
        addNewQuoteItem () {
            this.addNewQuoteItemDialogActive = true
        },

        saveNewQuoteItem () {
            if (!this.isAllowedTo('addItemToCart')) {
                return
            }

            this.addNewQuoteItemDialogLoading = true
            const url = CONFIG.API.ROUTES.QUOTES.ADD_QUOTE_ITEM.replace('{supplierQuoteId}', this.quoteData.supplierQuoteId)
            const jobName = url
            this.addJob(jobName)

            const payload = {
                productName: this.newQuoteItem.productName,
                cartId: this.quoteData.cartId,
                accountId: this.quoteData.accountId,
                applicationId: this.quoteData.applicationId,
                applicationName: this.quoteData.applicationName,
                attributes: this.newQuoteItem.attributes,
                quantity: this.newQuoteItem.quantity,
                deliveryInfo: this.newQuoteItem.deliveryInfo,
            }

            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Quote item added successfully`,
                })
                this.$v.newQuoteItem.$reset()
                this.addNewQuoteItemDialogActive = false
                this.getQuoteDetails()
                this.resetNewQuoteItemParams()
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Adding quote item failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(jobName)
                this.addNewQuoteItemDialogLoading = false
            })
        },
        formatDate (date) {
            return date.format('YYYY-MM-DD HH:mm')
        },
        dateRangeUpdate (value) {
            this.$v.quoteData.quoteStartDate.$model = moment(value.startDate)
            this.$v.quoteData.quoteEndDate.$model = moment(value.endDate)
            this.startDate = this.quoteData.quoteStartDate
            this.endDate = this.quoteData.quoteEndDate
        },
        handleRawQuoteItem ($event, key) {
            this.$set(this.$v.quoteItemsRaw.$model, key, $event)
        },

        updateQuoteDates () {
            if (!this.isAllowedTo('updateSupplierQuote')) {
                return
            }

            const url = CONFIG.API.ROUTES.QUOTES.UPDATE.replace('{supplierQuoteId}', this.quoteData.supplierQuoteId)
            const jobName = url
            this.addJob(jobName)
            this.isQuoteDataSubmitting = true

            this.$api.post(url, {
                quoteStartDate: this.startDate.format('YYYY-MM-DD HH:mm:00'),
                quoteEndDate: this.endDate.format('YYYY-MM-DD HH:mm:00'),
            }).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Quote edited successfully`,
                })
                this.$v.quoteData.$reset()
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Quote editing failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(jobName)
                this.isQuoteDataSubmitting = false
            })
        },
        sendQuoteToMerchant () {
            if (!this.isAllowedTo('sendQuoteToMerchant')) {
                return
            }

            this.isLoading = true
            const url = CONFIG.API.ROUTES.QUOTES.SEND_TO_MERCHANT.replace('{supplierQuoteId}', this.supplierQuoteId)
            this.addJob(url)
            this.$api.post(url, {
                accountId: this.accountId,
            }).then(({ data }) => {
                this.quoteCarriers = data.data.items
                this.$root.$emit('notification:global', {
                    message: `Quote was send to merchant.`,
                })
                this.$router.push({
                    name: 'QuotesOverview',
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Sending quote to merchant failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.isLoading = false
                this.finishJob(url)
            })
        },
        getQuoteDetails () {
            if (!this.isAllowedTo('getSupplierQuoteById')) {
                return
            }

            const url = CONFIG.API.ROUTES.QUOTES.GET.replace('{supplierQuoteId}', this.supplierQuoteId)

            this.addJob(url)
            return this.$api.get(url).then(({ data }) => {
                this.quoteParticipants = data.data.participants
                const quoteParticipantIds = this.quoteParticipants.map(supplier => supplier.supplierId)
                this.quoteItems = data.data.items.map(item => {
                    item = {
                        selectedParticipant: null,
                        selectedLocation: null,
                        selectedCarrier: null,
                        supplierPackageConfiguration: null,
                        productionDays: null,
                        targetDeliveryDate: null,
                        targetDispatchDate: null,
                        transitDays: null,
                        participantLocations: [],
                        ...item,
                    }

                    if (quoteParticipantIds.includes(item.producer.supplierId) === false) {
                        item.selectedParticipant = null
                        item.selectedLocation = null
                        item.producer.supplierId = null
                        item.producer.facilityId = null
                    }

                    return item
                })

                this.accountId = data.data.accountId
                this.quoteData = data.data
                this.startDate = this.quoteData.quoteStartDate ? moment(this.quoteData.quoteStartDate) : null
                this.endDate = this.quoteData.quoteEndDate ? moment(this.quoteData.quoteEndDate) : null
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
        getQuoteAttachments () {
            if (!this.isAllowedTo('listQuoteAttachmentsByQuoteIdAndActor')) {
                return
            }

            if (!this.quoteData || !this.quoteData.quoteId) {
                setTimeout(() => {
                    this.getQuoteAttachments()
                }, 300)
                return
            }
            const url = CONFIG.API.ROUTES.QUOTES.ATTACHMENTS.OVERVIEW.replace('{quoteId}', this.quoteData.quoteId)

            this.addJob(url)
            this.$api.post(url).then(({ data }) => {
                this.quoteAttachments = data.data
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
    },

    created () {
        this.getQuoteDetails().then(() => {
            this.getQuoteAttachments()
            this.resetNewQuoteItemParams()
        })

        const urlCarriers = CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.OVERVIEW_ALL
        this.addJob(urlCarriers)
        this.$api.post(urlCarriers, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.quoteCarriers = data.data.items
        }).catch(data => {
            this.$root.$emit('notification:global', {
                message: `Getting carriers failed. Please try again.`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.loading = false
            this.finishJob(urlCarriers)
        })
    },
}
</script>

<style scoped lang="scss">
    .quote-details {
        max-width: 650px;
    }
    .quote-summary {
        margin-bottom: 20px;
    }
    .quote-item {
        overflow: hidden;
        width: 50%;
        display: inline-block;
        vertical-align: top;
    }
    .quote-items {
        position: relative;
    }

    .actions {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 4;
    }
    .loading-indicator {
        justify-self: center;
        align-self: center;
        margin-top: 90px;
        text-align: center;
    }
    .quote-details-card {
        margin-top: 3px;
        margin-left: 0;
        margin-right: 0;
    }
    .attribute-wrapper {
        position: relative;
        padding-left: 10px;
        padding-bottom: 20px;

    }
    .attribute {
        position: relative;
        border-left: 3px solid #dedede;
        padding-left: 5px;
        margin-bottom: 3px;
        margin-top: 1px;
    }

    .attribute-remove,
    .attribute-add {
        position: absolute;
        cursor: pointer;
        display: block;
        padding: 4px;

        &:hover {
            text-decoration: none;
        }
    }

    .attribute-add {
        left: 0;
        bottom: 0;
    }

    .attribute-remove {
        right: 0;
        bottom: 50%;
    }

    .attribute-field {
        &--name {
            margin-right: 5px;
        }
        &--option {
            margin-left: 5px;
        }
    }
    .add-product-button {
        float: right;
    }
    .attachment-permission {
        margin-top: 4px;
        margin-bottom: 0;
    }
    .attachment-table table {
        border-spacing: 0 0.5em;
    }
</style>
